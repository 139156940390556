import React, {useState} from "react"
import Modal from "react-modal"

import * as style from "../privacy.module.css"
import Seo from "../../components/seo"
import Footer from "../../components/footer/Footer"
import ContactForm from "../../components/form/ContactForm"
import NavbarPrivacy from "../../components/navbar/NavbarPrivacy"
import {LocaleProvider} from "../../hooks/useLocale";
import useTranslation from "../../hooks/useTranslation";

const Privacy0 = ({pageContext, pageContext: {locale}}) => {

  const translations = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const customStyles = {
    content: {},
    overlay: {
      backgroundColor: "rgba(0,0,0,0.7)",
    },
  }
  Modal.setAppElement("body")
  return (
    <>
      <Seo locale={locale} translations={translations} title="privacy policy"/>
      <NavbarPrivacy locale={locale}/>
      <div className={style.wrapper}>
        <ol>
          {/* 1 */}
          <li>Personal Data Controller</li>
          <p>
            The Controller of your personal data is Enterosoft Sp. z o. o. with its registered
            office in Gdańsk, ul. Starodworska 1, 80-137 Gdańsk, court registration (KRS):
            0000850020, Tax ID (NIP): 5833401725, National Official Business Register (REGON):
            386524824 (hereinafter: we). You can contact us in the following way:
          </p>
          <ul>
            <li>
              by letter at the following address: Enterosoft Sp. z o. o., ul. Starodworska 1, 80-137
              Gdańsk

            </li>
            <li>by e-mail: privacy@enterosoft.com</li>
          </ul>
          {/* 2 */}
          <li>Data Protection Officer</li>
          <p>
            As we have no duty to do so, we have not appointed the Data Protection Officer. You may
            obtain a response to any doubts and questions about personal data by sending an e-mail
            to: privacy@enterosoft.com
          </p>
          {/* 3 */}

          <li>Definitions</li>
          <p>Application, Service – an electronic service offered by the Controller in compliance
            with the Rules.
          </p>
          <p>User – a natural person irrespective of age, or an entrepreneur in the meaning of the
            Entrepreneurs’ Law Act of 6 March 2018, a person acting on the entrepreneur’s behalf or
            collaborating with the entrepreneur, who uses the Services made available by the
            Controller in compliance with the Rules.
          </p>
          <p>
            Account – the User account, a set of resources and authorisations allocated to the
            particular User.

          </p>
          <p>
            Password – an encrypted sequence of characters, alphanumeric (consisting of letters and
            digits) in principle, permitting User authorisation and permitting his or her access to
            the certain functions of the Service related to the User's activity, which is only
            possible following User authentication. Entering the Password may be necessary for the
            service to perform the User’s instructions.

          </p>

          <p>
            Cookie files or other similar technologies – Cookie files or other tools used in
            Applications, intended for similar purposes i.e. collection, recording and storage of
            information about Users’ activity.

          </p>
          <p>
            Rules – the Rules for the provision of electronic services, as made available by the
            Controller.

          </p>
          <p>
            GDPR – Regulation (EU) 2016/679 of the European Parliament and of the Council of 27
            April 2016 on the protection of natural persons with regard to the processing of
            personal data and on the free movement of such data, and repealing Directive 95/46/EC
            (General Data Protection Regulation).

          </p>
          <p>
            Mobile Device – a portable electronic device permitting data processing, reception and
            transmission without the need to maintain a cable connection with the Internet.

          </p>
          <li>Purposes, time and legal basis for data processing</li>
          <p>When our Applications are used, we will process the Users’ data for the following
            purposes:
          </p>
          <ul>
            <li>
              provision of the service and ensuring the option to use the functionalities of
              Applications. If the Application permits sharing information recorded on the User
              Account, we will also gather information on other Users.
              Data are processed in compliance with Article 6(1)(b) of the GDPR, to the extent as
              the Service is used, and are processed throughout the time of the provision of the
              Service and for 5 years from the end of the calendar year in which cooperation ended.

            </li>
            <li>complaint handling: in the course of complaint handling, we process several data,
              including the User’s first and last name, e-mail, information on the User’s taking
              advantage of the Services, Cookie Files or other similar technologies and information
              about devices.
              Data are processed in compliance with Article 6(1)(b) of the GDPR for the provision of
              the Services and over the time necessary to handle complaints, not longer than for 3
              months from the end of the complaint procedure, for storage purposes, in case there is
              a need for defence against claims, if any.
            </li>
            <li>conducting explanatory proceedings: if explanatory proceedings are undertaken which
              concern any breach of the Rules, law, rules of social contract or good manners, or if
              proceedings are undertaken to pursue claims by the Controller or other Users or
              entities, for the defence against claims made by Users or other entities, we may
              process personal data of specific Users until the end of the proceedings and until the
              expiry of the time for prescription of the Controller’s claims against the User, such
              time usually being 3 years.
              If personal data are processed for the pursuance of other Users’ claims, those data
              may be disclosed for that purpose to another User, entity or public authority entitled
              under law, e.g. courts, the Police or prosecution service. Those data will then be
              processed, including disclosure, in compliance with Article 6(1)(c) of the GDPR, i.e.
              to perform a legal obligation, or in compliance with Article 6(1)(f) of the GDPR, i.e.
              in the Controller’s legitimate interest consisting in pursuing his claims against the
              User. The Controller’s legitimate interest will then be overriding in relation to his
              rights and freedoms.
            </li>
            <li>
              analysing traffic at the website and measuring Application usage (Google Analytics):
              we process information about Service usage, such as the IP address, information about
              the Mobile Device on which the Application is installed (the operating system, device
              model, Application configuration), data about errors from the Application, time and
              date of Application usage and the advertising ID as well as cookie files or other
              similar technologies. You can manage your cookie settings from within your web browser
              or by installing special add-ons that allow you to control your cookies.
              These data are processed on the basis of Article 6(1)(f) of the GDPR for the
              Controller’s legitimate interest in facilitating the use of the Service and improving
              its quality and functionality. The information is processed for 3 months from its
              acquisition, but no longer than until the data subject has filed an objection.

            </li>
            <li>
              site protection against automatons (Google Recaptha)
            </li>
          </ul>
          <p>Personal data collection during a visit at our website:</p>
          <ul>
            <li>
              When the website is used only to obtain information, i.e. if you do not register or
              otherwise provide us with information, we only collect personal data that are
              transmitted to our server by your browser. If you wish to browse our website, we
              collect the following data, which we need for technical reasons in order to display
              our website and guarantee its stability and security (the legal basis is Article
              6(1)(f) of the GDPR):
              <ul>
                <li>
                  IP address
                </li>
                <li>
                  date and time of the enquiry
                </li>
                <li>
                  time difference in relation to Greenwich Mean Time (GMT)
                </li>
                <li>
                  request content (a particular site)
                </li>
                <li>
                  access status/HTTP response code
                </li>
                <li>
                  data volume transfer
                </li>
                <li>
                  the website from which the request originates
                </li>
                <li>
                  browser
                </li>
                <li>
                  operating system and its interface
                </li>
                <li>
                  browser language and software version.
                </li>
              </ul>
            </li>
            <li>
              When you use our website cookie files are saved on your computer. Cookie files are
              small text files recorded in the memory of your computer and allocated to the browser
              used by you. Thanks to these files, we receive specified information. Cookie files may
              not start any programmes or transfer viruses to your computer.
            </li>
            <li>
              Types of cookie files:
              <ul>
                <li>
                  Transient cookies are temporary cookies that are automatically deleted when you
                  close the browser. These include, in particular, session cookies. They store the
                  so-called session ID, which permits the allocation of various browser requests to
                  a common session. Thanks to that, it is possible to recognise your computer again
                  if you return to our website. Session cookies are deleted when you log out or
                  close your browser.
                </li>
                <li>
                  Persistent cookies are permanent files that are deleted automatically after
                  specific time, which may vary depending on the type of the file. Cookie files may
                  be deleted at any time through the use of the security settings of the browser.
                </li>
                <li>
                  We use cookie files to be able to identify you during your next visit.
                </li>
                <li>
                  The above data are processed on the basis of Article 6(1)(f) of the GDPR for the
                  Controller’s legitimate interest in facilitating the use of the Service and
                  improving its quality and functionality. The information is processed for 3 months
                  from its acquisition, but no longer than until the data subject has filed an
                  objection.
                </li>
              </ul>
            </li>
            <li>
              You can configure your browser settings as you wish and, for example, refuse to accept
              third-party or all cookie files. Please note, however, that then you will not be able
              to use all the functions of this website to the full extent.
            </li>
          </ul>
          <p>
            Use of data in the course of our cooperation:
          </p>
          <ul>
            <li>
              Marketing activities: we may send information to Users with regard to our promotional
              activities. We do not undertake any profiling activities, in particular we do not
              analyse information we obtain about Users and do not create their behavioural profiles
              for marketing purposes. Processing takes place on the basis of the Controller's
              legitimate interest (Article 6(1)(f) of the GDPR) in undertaking marketing activities
              in order to present marketing information.
            </li>
            <li>
              Other uses: The data will be used for ongoing billing, documenting the performance of
              the agreement, as well as for contact for the purposes mentioned above. And also for
              diagnostics, traffic analysis and marketing campaign effectiveness analysis (Google
              Analytics).
            </li>
          </ul>
          <p>
            Account deletion or data change
          </p>
          <ul>
            <li>
              The User may delete his or her account or change his or her personal data by using the
              options available in the Services. In some Services, the Account is also deleted after
              1 month from the definite end of the Service usage.
            </li>

          </ul>
        
          {/* 6 */}
          <li>Data recipients</li>
          <p>We will transfer your personal data to:</p>
          <ul>
            <li>
              our suppliers to whom we outsource personal data processing services, e.g. IT service
              providers, external financial and accounting services, and legal service providers.
              Such entities process data under an agreement with us and only in accordance with our
              instructions,
            </li>
            <li>
              Google reCaptcha – the website is protected by reCAPTCHA and the Google's Privacy
              Policy and Terms of Service apply:
            </li>
            <ul>
              <li><a
                href='https://policies.google.com/privacy'>https://policies.google.com/privacy</a>
              </li>
              <li><a href="https://policies.google.com/terms">https://policies.google.com/terms</a>
              </li>
            </ul>
            <li>
              Google Analytics – website traffic is analysed by analytics and the Google Privacy
              Policy and Terms of Service apply:
            </li>
            <ul>
              <li><a
                href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
              </li>
              <li><a href="https://policies.google.com/terms">https://policies.google.com/terms</a>
              </li>
            </ul>
            <li>
              Google Play Services (privacy policy: https://policies.google.com/privacy),
              <ul>
                <li><a
                  href="https://support.google.com/admob/answer/6128543?hl =en)">AdMob (privacy
                  policy: https://support.google.com/admob/answer/6128543?hl =en)</a>
                </li>
              </ul>
            </li>
            <li>
              Unity <a href={"https://unity3d.com/legal/privacy-policy"}>privacy policy:
              https://unity3d.com/legal/privacy-policy,</a></li>
            <li>GameAnalytics <a href={"https://gameanalytics.com/privacy/"}>privacy policy:
              https://gameanalytics.com/privacy/</a>.
            </li>
          </ul>
          <br/>
          {/* 7 */}
          <li>
            Your rights in relation to the processing of your personal data and automated
            decision-making
          </li>
          <p>
            You have the following rights in relation to the processing of your personal data:
          </p>
          <ul>
            <li>the right to withdraw your consent to data processing,
            </li>
            <li>right of access to your personal data,</li>
            <li>pthe right to request the rectification of your personal data,</li>
            <li>the right to request the erasure of your personal data,</li>
            <li>
              the right to request the restriction of processing of your personal data,
            </li>
            <li>
              the right to object the processing of your data due to your particular situation – in
              cases where we process your data on the basis of our legitimate interest,
            </li>
            <li>
              the right to the portability of your personal data, i.e. the right to receive your
              personal data from us in a structured, commonly used, machine-readable format. You may
              transmit these data to another controller or request us to send your data to another
              controller. However, we will do that only if such transmission is technically
              possible. You have the right to personal data portability only with respect to the
              data processed by us on the basis of the agreement with you or your consent,
              <br/> <br/>
              Right to withdrawal of consent <br/>
              To the extent that your data are processed on the basis of your consent, you have the
              right to withdraw your consent to data processing at any time. The withdrawal of
              consent does not affect the lawfulness of processing based on your consent before its
              withdrawal. You may withdraw your consent by sending a statement of consent withdrawal
              to our correspondence or e-mail address.
              To exercise the above rights, please contact us.
              The right to lodge a complaint with an authority
              You also have the right to lodge a complaint with the supervisory authority dealing
              with personal data protection i.e. the President of the Personal Data Protection
              Office.

            </li>
          </ul>
          {/* 8 */}
          <li>Automated data processing and profiling</li>
          <ul>
            <li>
              We point out that, with regard to data concerning the way in which the services are
              provided by us, it is possible to implement IT solutions resulting in the automatic
              notification of individual categories of events to the Controller, in particular of
              the balance and frequency of billings or purchase preferences. In each case, such
              information may only result in the presentation of potential proposals for the
              purchase of goods or services to you if you have given the relevant consent or
              voluntarily used the solutions made available by us.
            </li>
            <li>
              Our website uses Google Analytics, a web analytics service provided by Google Inc.
              (“Google”). Google Analytics uses the so-called “Cookies” i.e. text files saved on
              your computer and enabling the analysis of website usage by you.
            </li>
            <li>
              We use Google Analytics to analyse the use of our website and improve it on a regular
              basis. Thanks to the statistics, we can improve our offer and make it more interesting
              for you as the Users. With regard to the exceptional cases in which personal data are
              transferred to the USA, Google complies with the EU-US Privacy Shield agreement, {' '}
              <a
                href={"www.privacyshield.gov/EU-US-Framework"}>www.privacyshield.gov/EU-US-Framework. </a> The
              legal basis for the use of Google Analytics
              is Article 6(1)(f) of the GDPR.
            </li>
            <li>
              Information of the external provider: Google Dublin, Google Ireland Ltd., Gordon
              House, Barrow Street, Dublin 4, Ireland, fax: +353 (1) 436 1001. Conditions of
              usage {' '}
              <a
                href={"https://policies.google.com/privacy"}>https://policies.google.com/privacy</a> ,
              Information on personal data protection:
              <a href={"https://policies.google.com/terms"}>https://policies.google.com/terms</a> .
            </li>
          </ul>
          {/* 9 */}
          <br/>
          <li>Transfer of personal data to third countries</li>
          <p>We may transfer your personal data to third countries in connection with the use of
            tools that store personal data on servers located in third countries, in particular the
            USA. The providers of these tools guarantee an adequate level of protection of personal
            data through the relevant compliance mechanisms provided by the GDPR, in particular the
            use of standard contractual clauses.</p>
          <li>Security</li>
          <p>
            We ensure the confidentiality of any personal data provided to us. We ensure that
            appropriate security and data protection measures are taken as required by data
            protection laws. Personal data are collected with due care and adequately protected from
            access by unauthorised persons.
          </p>
          <li>
            Changes in the Privacy Policy
          </li>
          <p>
            The Privacy Policy may be supplemented or updated as necessary in order to provide
            current and reliable information to Users with regard to their personal data and
            information about such data. Any changes to the Privacy Policy will be notified to the
            Users in the Rules of the Service and, if the Controller deems it appropriate or
            necessary, at the e-mail address provided to him.
          </p>

        </ol>
      </div>
      <Footer locale={locale} translations={translations} openModal={() => setModalOpen(true)}/>
      <Modal className="contact__modal" style={customStyles} isOpen={modalOpen}>
        <ContactForm translations={translations} locale={locale}
                     closeModal={() => setModalOpen(false)}/>
      </Modal>
    </>

  )
}

const Privacy = ({pageContext, children}) => (
  <LocaleProvider>
    <Privacy0 pageContext={pageContext}>{children}</Privacy0>
  </LocaleProvider>
)
export default Privacy 