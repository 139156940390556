import * as React from "react"
import * as style from "./navbarPolicy.module.css"
import Logo from "../../assets/images/logo.svg"
import { Link } from "gatsby"
import {multilingualLink} from "../../utils/multilaguagesLink";
import {useLocalStorage} from "../../hooks/useLocalStorage";

const NavbarPrivacy = () => {
  const [storedLang] = useLocalStorage()

  return (
    <>
      <div className={style.navbarWrapper}>
        <nav className={style.navbar}>
          <Link to={multilingualLink('', storedLang)}>
            <Logo className={style.logo} />
          </Link>
        </nav>
      </div>
    </>
  )
}

export default NavbarPrivacy